// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-counselling-jsx": () => import("./../src/pages/counselling.jsx" /* webpackChunkName: "component---src-pages-counselling-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mediation-jsx": () => import("./../src/pages/mediation.jsx" /* webpackChunkName: "component---src-pages-mediation-jsx" */),
  "component---src-pages-no-content-jsx": () => import("./../src/pages/NoContent.jsx" /* webpackChunkName: "component---src-pages-no-content-jsx" */),
  "component---src-pages-psychology-jsx": () => import("./../src/pages/psychology.jsx" /* webpackChunkName: "component---src-pages-psychology-jsx" */)
}

